import clsx from "clsx";
import s from "./HeaderToggle.module.scss";

/**
 * @typedef {object} HeaderToggleProps
 * @property {boolean} isActive
 * @property {boolean} [isDarkText]
 * @property {() => void} handleClick
 *
 * @param {HeaderToggleProps} param1
 */
const HeaderToggle = ({ isActive, handleClick, isDarkText = false }) => {
	return (
		<div className={s._Wrapper} onClick={handleClick}>
			<div
				className={clsx(
					!isActive ? s._Icon : [s._Icon, s["_Icon--Active"]],
					isDarkText && s._IsDarkText,
				)}
			>
				<span></span>
			</div>
		</div>
	);
};

export default HeaderToggle;
