import { Icon } from "@/components/atoms/Icons";
import img1 from "@/img/home/HomeWhatDrivesUs1.svg";
import clsx from "clsx";
import s from "./HomeWhatDrivesUs.module.scss";

const HomeWhatDrivesUs = () => {
	return (
		<div className={s._Wrapper} id="about-us">
			<div className={clsx(s._Container, "container")}>
				<div className={s._Top}>
					<img
						className={s._Image}
						src={img1}
						alt="What Drives Us"
						height={365}
						width={658}
						data-aos="fade-right"
					/>
					<div className={s._WhatDrivesUs}>
						<div className={s._Header}>
							<h2 className={s._Title}>
								What Drives Us<span>.</span>
							</h2>
							<p className={s._Description}>
								At Ghyser, we are motivated by the vision of building a sustainable energy future.
								We believe offshore green hydrogen can make this vision a reality by leveraging the
								vast renewable resources of the oceans. Our goal is to pioneer clean hydrogen
								production at a scale that accelerates decarbonization globally. We are driven to
								apply innovative thinking in pursuit of meaningful technology advances that increase
								adoption. We measure our success not only in economic terms but also by the local
								capacity we build and emissions we displace.
								<br />
								<br />
								Let’s light the path toward a net zero future fueled by green hydrogen.
							</p>
						</div>
						<div className={s._CTA}>
							<a href="/contact" className={s._Button}>
								<p className={s._Text}>Get in touch</p>
								<Icon.IcArrowRightLong />
							</a>
						</div>
					</div>
				</div>
				{/* <h2 className={s._PartnerTitle}>Our Partners</h2>
				<div className={s._Images}>
					<img src={iig} alt="IIG logo" />
					<img src={airLiquide} alt="Air Liquide logo" />
					<img src={jinko} alt="Jinko logo" />
					<img src={masdar} alt="Masdar logo" />
					<img src={doosan} alt="Doosan logo" />
					<img src={vestas} alt="Vestas logo" />
					<img src={cop28} alt="Cop28 logo" />
				</div> */}
			</div>
		</div>
	);
};

export default HomeWhatDrivesUs;
