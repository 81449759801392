import EmbedVideo from "@/components/atoms/EmbedVideo";
import { Icon } from "@/components/atoms/Icons";
import MouseFollowSVG from "@/components/atoms/MouseFollowSvg";
import heroBg from "@/img/home/home-hero.jpg";
import videoHero from "@/video/head_video.mp4";
import AOS from "aos";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import s from "./HomeHeroBanner.module.scss";

const HomeHeroBanner = () => {
	const [isVideoReady, serIsVideoReady] = useState(false);

	const handleOnLoadVideo = () => {
		serIsVideoReady(true);
	};

	useEffect(() => {
		AOS.init({ duration: 1000, offset: 50, easing: "ease" });
	}, []);

	return (
		<div className={s._Wrapper}>
			<EmbedVideo
				onLoadedDataVideo={handleOnLoadVideo}
				videoData={videoHero}
				className={s._Video}
			/>
			<img
				src={heroBg}
				width={1920}
				height={890}
				style={{ opacity: isVideoReady ? "0" : "1" }}
				alt="contact-bg"
			/>
			<div className={clsx("container", s._Content)}>
				<div className={clsx(s._Content__Head)}>
					<div className={s._Content__Head__Icon}>
						<MouseFollowSVG />
					</div>
					<h1>
						{"Pioneering Sustainable Hydrogen"}
						<span>.</span> {"Patented Solutions for a Greener Future"}
						<span>.</span>
					</h1>
				</div>

				<div className={s._Description}>
					<p className={s._Description__Text} data-aos-delay={300}>
						Fueled by Nature, Delivered by Ghyser.
					</p>
					<Link
						to="/contact"
						className={s._Description__Button}
						data-aos="fade-right"
						data-aos-delay={600}
					>
						Get in touch
						<Icon.IcArrowRightLong />
					</Link>
				</div>
			</div>
		</div>
	);
};

export default HomeHeroBanner;
