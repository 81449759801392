import { Icon } from "@/components/atoms/Icons";
import ImageHeader from "@/components/atoms/ImageHeader";
import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import "@/css/news.css";
import "@/css/style.css";
import HomeTeam from "@/features/home/components/HomeTeam";
import ServicesIcon from "@/img/about/Services.svg";
import SustainabilityIcon from "@/img/about/Sustainability.svg";
import VisionIcon from "@/img/about/vision.svg";
import heroBg from "@/img/atf/About us.webp";
import AOS from "aos";
import { useEffect } from "react";
import s from "./AboutUs.module.scss";

export default function Vision() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const contentItems = [
		{
			title: "Our vision",
			content:
				"Our journey is fueled by a bold vision: to reshape the dynamics of energy production and consumption, catalyzing a positive impact on our planet's well-being. At the heart of our operations lie four guiding principles that steer every decision we make: sustainability, innovation, integrity, and excellence.",
			icon: VisionIcon,
		},
		{
			title: "Services",
			content:
				"Ghyser offers a suite of services that harness the forces of nature. Our primary focus centers on offshore green hydrogen production, empowered by our advanced platforms. Leveraging solar and offshore wind sources, as well as desalinated seawater, we deliver potent and sustainable green hydrogen solutions. Our commitment extends to the transport and utilization of green hydrogen, efficient waste management, and responsible water production practices.",
			icon: ServicesIcon,
		},
		{
			title: "Sustainability at our core",
			content:
				"Sustainability is the nucleus of Ghyser’s existence. We are driven by a dual mission: to provide accessible, clean energy and safeguard our planet's future. Our technological prowess revolves around sustainable utilization of natural resources, particularly through offshore green hydrogen production. Our resolute dedication to environmental stewardship propels us into a leadership role in global decarbonization endeavors.",
			icon: SustainabilityIcon,
		},
	];

	useEffect(() => {
		AOS.init({
			duration: 800,
			offset: 0,
			easing: "ease",
		});
	}, []);
	return (
		<>
			<Header isScrolledInit />
			<div className="vision">
				<ImageHeader title="About us" imageData={heroBg} />
				<div className={s._ContentWrapper}>
					<div className={s._Content}>
						<div className={s._ContentLeft}>
							{contentItems.map((item, index) => (
								<div key={index} className={s._ContentItem}>
									<div className={s._ContentItemIcon}>
										<img src={item.icon} alt="icon" />
										<h3>{item.title}</h3>
									</div>
									<div className={s._ContentItemText}>
										<p>{item.content}</p>
									</div>
								</div>
							))}
						</div>
						<div className={s._ContentRight}>
							<h2>
								Company overview<span>.</span>
							</h2>
							<p className={s._ContentRightText}>
								Ghyser is a global pioneer in the production of sustainable energy, headquartered in
								Masdar City, Abu Dhabi, UAE. We are the first company to commercialize offshore
								production of green hydrogen, leveraging the immense power of nature to provide
								innovative and sustainable energy solutions.
							</p>
							<div className={s._CTA}>
								<a href="/contact" className={s._Button}>
									<p className={s._Text}>Get in touch</p>
									<Icon.IcArrowRightLong />
								</a>
							</div>
						</div>
					</div>

					<HomeTeam />

					{/* <div className={clsx(s._Partners, "container")}>
						<h2>Our Partners</h2>
						<div className={s._Images}>
							<img src={iig} alt="IIG logo" />
							<img src={airLiquide} alt="Air Liquide logo" />
							<img src={jinko} alt="Jinko logo" />
							<img src={masdar} alt="Masdar logo" />
							<img src={doosan} alt="Doosan logo" />
							<img src={vestas} alt="Vestas logo" />
							<img src={cop28} alt="Cop28 logo" />
						</div>
					</div> */}
				</div>
			</div>
			<Footer />
		</>
	);
}
