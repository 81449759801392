/* eslint-disable no-undef */
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import s from "./Header.module.scss";
import HeaderLogo from "./HeaderLogo";
import HeaderMenu from "./HeaderMenu";
import HeaderToggle from "./HeaderToggle";

/**
 *
 * @typedef {object} NewsSlideProps
 * @property {boolean} [isScrolledInit]
 * @property {boolean} [isDarkText]
 *
 * @param {NewsSlideProps} param1
 */
const Header = ({ isScrolledInit = false, isDarkText = false }) => {
	const [isScrolled, setIsScrolled] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [activeMenuItem, setActiveMenuItem] = useState('home');
	const location = useLocation();

	const listenScrollEvent = useCallback(() => {
		if (isScrolledInit) return;

		if (window.scrollY < 73) {
			return setIsScrolled(false);
		} else if (window.scrollY > 70) {
			return setIsScrolled(true);
		}
	}, [isScrolledInit]);

	useEffect(() => {
		window.addEventListener("scroll", listenScrollEvent);
		const currentPath = location.pathname.replace('/', '');
  		setActiveMenuItem(currentPath);

		return () => window.removeEventListener("scroll", listenScrollEvent);
	}, [listenScrollEvent, location]);

	return (
		<>
			<header
				className={clsx(
					s._Wrapper,
					isScrolled || isScrolledInit ? s["_Wrapper--Scrolled"] : "",
					isDarkText && s._IsDarkText,
				)}
			>
				<div className={clsx("container", s._Wrapper__Container)}>
					<HeaderLogo isActive={isActive} isDarkText={isDarkText} />
					<HeaderToggle
						isActive={isActive}
						isDarkText={isDarkText}
						handleClick={() => setIsActive((prev) => !prev)}
					/>
					<HeaderMenu
						isActive={isActive}
						isDarkText={isDarkText}
						handleClick={() => setIsActive((prev) => !prev)}
						activeItem={activeMenuItem}
					/>
				</div>
			</header>
		</>
	);
};

export default Header;
