import clsx from "clsx";
import s from "./EmbedVideo.module.scss";

/**
 * @component
 * @param {Object} props
 * @property {(e) => void} [onLoadedDataVideo]
 * @property {string} videoData
 * @property {string} [className]
 * @returns {JSX.Element}
 */

const EmbedVideo = ({ onLoadedDataVideo, className, videoData }) => {
	const handleLoadedData = (e) => {
		if (onLoadedDataVideo) {
			onLoadedDataVideo(e);
		}
	};
	return (
		<div className={clsx(s._Wrapper, className)}>
			<video
				loop
				muted
				autoPlay
				playsInline
				src={videoData}
				onLoadedData={handleLoadedData}
			></video>
		</div>
	);
};

export default EmbedVideo;
