import ImageHeader from "@/components/atoms/ImageHeader";
import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import heroBg from "@/img/vision/hero.webp";
import { useEffect } from "react";
import InvestorsForm from "../components/InvestorsForm";

export default function Investors() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<ImageHeader title={`Invest in a greener tomorrow`} imageData={heroBg} />
			<InvestorsForm />
			<Footer />
		</>
	);
}
