import BadgePill from "@/components/atoms/BadgePill";
import clsx from "clsx";
import { Link } from "react-router-dom";
import s from "./CardNews.module.scss";

const CardNews = ({ newsName, newsCategories, newsPostDate, newsTag, newsThumbnail, newsSlug }) => {
	return (
		<div className={clsx(s._Wrapper)}>
			<div className={clsx(s._CardThumbnail)}>
				<img src={newsThumbnail} alt="slide" loading="lazy" width={578} height={339} />
			</div>
			<div className={clsx(s._CardContent)}>
				<div className={clsx(s._Head)}>
					{newsCategories?.[0] && <BadgePill>{newsCategories?.[0]}</BadgePill>}
					<Link to={`${newsSlug}`}>
						<svg
							width="100%"
							height="100%"
							viewBox="0 0 56 56"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="28" cy="28" r="28" fill="currentColor" />
							<path
								d="M22.6249 31.9596L21.9178 32.6667L23.332 34.081L24.0391 33.3739L22.6249 31.9596ZM34.2315 22.7673C34.2315 22.215 33.7838 21.7673 33.2315 21.7673L24.2315 21.7673C23.6792 21.7673 23.2315 22.215 23.2315 22.7673C23.2315 23.3195 23.6792 23.7673 24.2315 23.7673L32.2315 23.7673L32.2315 31.7673C32.2315 32.3195 32.6792 32.7673 33.2315 32.7673C33.7838 32.7673 34.2315 32.3195 34.2315 31.7673L34.2315 22.7673ZM24.0391 33.3739L33.9386 23.4744L32.5244 22.0601L22.6249 31.9596L24.0391 33.3739Z"
								fill="white"
							/>
						</svg>
					</Link>
				</div>
				<div className={clsx(s._Body)}>
					<p>{newsName}</p>
				</div>
				<div className={clsx(s._Foot)}>
					<p>{newsTag}</p>
					<p>{newsPostDate}</p>
				</div>
			</div>
		</div>
	);
};

export default CardNews;
