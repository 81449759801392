import Aos from "aos";
import clsx from "clsx";
import { useEffect } from "react";
import s from "./HomeTeam.module.scss";

/**
 * @param {Object} props
 * @property {string} name
 * @property {string} role
 * @property {string} description
 * @property {string} linkedinUrl
 * @property {string} twitterUrl
 * @property {string} imageData
 * @returns {JSX.Element}
 */

const HomeTeamItem = ({ name, role, description, linkedinUrl, twitterUrl, imageData }) => {
	useEffect(() => {
		Aos.init({ duration: 1000, offset: 50, easing: "ease" });
	}, []);
	return (
		<div className={clsx(s._ItemWrapper)}>
			<div className={clsx(s._NameWrapper)}>
				<p className={clsx(s._Name)}>{name}</p>
				<p className={clsx(s._Role)}>{role}</p>
				<div className={clsx(s._ButtonWrapper)}>
					{linkedinUrl && (
						<a
							className={clsx(s._Button, s._IconAfter)}
							href={linkedinUrl}
							target="_blank"
							rel="noreferrer"
							data-aos="fade-right"
							data-aos-delay={100}
						>
							Linkedin{" "}
							<svg
								width="26"
								height="13"
								viewBox="0 0 26 13"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M18.6849 0L17.4554 1.0925L22.6716 5.72752H0V7.27263H22.6714L17.4554 11.9075L18.6849 13L26 6.5L18.6849 0Z"
									fill="currentColor"
								/>
							</svg>
						</a>
					)}
					{twitterUrl && (
						<a
							className={clsx(s._Button, s._Twitter)}
							href={twitterUrl}
							target="_blank"
							rel="noreferrer"
							data-aos="fade-right"
							data-aos-delay={100}
						>
							<div>
								<svg
									width="100%"
									height="100%"
									viewBox="0 0 30 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M23.6232 10.5747C23.6364 10.7659 23.6364 10.9571 23.6364 11.1501C23.6364 17.0303 19.1599 23.812 10.9745 23.812V23.8085C8.55653 23.812 6.18876 23.1194 4.1532 21.8135C4.50479 21.8558 4.85815 21.8769 5.21239 21.8778C7.21623 21.8796 9.16279 21.2072 10.7392 19.9691C8.83498 19.933 7.16512 18.6914 6.58177 16.8788C7.24883 17.0074 7.93617 16.981 8.59089 16.8021C6.5148 16.3827 5.02117 14.5586 5.02117 12.4402C5.02117 12.4208 5.02117 12.4023 5.02117 12.3838C5.63977 12.7283 6.33239 12.9196 7.04087 12.9407C5.0855 11.6339 4.48276 9.03262 5.66357 6.99882C7.92295 9.77899 11.2565 11.4691 14.835 11.648C14.4764 10.1024 14.9663 8.48275 16.1225 7.39624C17.9148 5.7114 20.7338 5.79776 22.4186 7.58922C23.4152 7.39272 24.3704 7.02702 25.2446 6.50888C24.9124 7.539 24.2171 8.41402 23.2883 8.97006C24.1704 8.86607 25.0322 8.62991 25.8438 8.26951C25.2463 9.1648 24.4938 9.94466 23.6232 10.5747Z"
										fill="currentColor"
									/>
								</svg>
							</div>
						</a>
					)}
				</div>
			</div>
			<div className={clsx(s._DescriptionWrapper)}>
				<p className={clsx(s._Description)}>{description}</p>
			</div>
			<div className={clsx(s._ImageWrapper)} data-aos-delay={100}>
				<img src={imageData} alt={name} width={509} height={509} loading="lazy" />
			</div>
		</div>
	);
};

export default HomeTeamItem;
