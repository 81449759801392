import img1 from "@/img/home/HomeFuture1.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import clsx from "clsx";
import { useEffect } from "react";
import s from "./HomeFuture.module.scss";
import HomeFutureItem from "./HomeFutureItem.component";

const squaresData = [
	{
		title: "Annual investment",
		prefix: "$",
		suffix: "",
		value: 1.5,
		unit: "Trillion",
		description: (
			<>
				Annual investment needed to achieve net{" "}
				<span className="primary body-xs">zero emissions</span> by 2050.
			</>
		),
		decimals: 1,
	},
	{
		title: "Global warming",
		prefix: "",
		suffix: "°C",
		value: 0.5,
		unit: "",
		description: (
			<>
				Global warming <span className="primary body-xs">avoided</span> through rapid renewable{" "}
				energy transition.
			</>
		),
		decimals: 1,
	},
	{
		title: "Renewable energy",
		prefix: "",
		suffix: "",
		value: 75,
		unit: "Million",
		description: (
			<>
				Jobs created by transition to <span className="primary body-xs">renewable energy</span> by{" "}
				2050.
			</>
		),
		decimals: 0,
	},
	{
		title: "Government commitments",
		prefix: "$",
		suffix: "",
		value: 300,
		unit: "Billion",
		description: (
			<>
				In government commitments for hydrogen development{" "}
				<span className="primary body-xs">by 2030</span>
			</>
		),
		decimals: 0,
	},
];

const HomeFutureOfGreenEnergy = () => {
	useEffect(() => {
		Aos.init();
	}, []);
	return (
		<div className={s._Wrapper} id="future">
			<div className={s._Container}>
				<div className={clsx(s._Header, "container")}>
					<h2 className={s._Title}>
						Paving the future of <span>green</span> energy<span>.</span>
					</h2>
					<div className={s._Content}>
						<p className={clsx("body-md", "neutral-100", s._Text)}>
							Ghyser leads the way with cutting-edge patented technology, harnessing green hydrogen
							to revolutionize energy production and pave the path to a greener, sustainable future.
						</p>
					</div>
				</div>
				<div className={s._Squares}>
					<div className={s._colIitem}>
						<img
							className={s._Image}
							src={img1}
							alt="Paving the future of green energy."
							height={575}
							width={960}
						/>
					</div>
					<div className={clsx(s._colIitem, s._colIitem__Content)}>
						<div className={s._NumerItemWrapper}>
							{squaresData.map((item, i) => {
								return (
									<HomeFutureItem
										title={item.title}
										description={item.description}
										decimals={item.decimals}
										value={item.value}
										key={item.title}
										prefix={item.prefix}
										suffix={item.suffix}
										unit={item.unit}
									/>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeFutureOfGreenEnergy;
