import { Icon } from "@/components/atoms/Icons";
import { useEffect, useRef } from "react";
import { useCountUp } from "react-countup";
import s from "./HomeFuture.module.scss";

const HomeFutureItem = ({
	title,
	prefix = "",
	suffix = "",
	value,
	unit = "",
	description,
	decimals,
}) => {
	const countUpRef = useRef(null);

	const { start } = useCountUp({
		end: value,
		ref: countUpRef,
		decimal: ".",
		decimals: decimals,
		startOnMount: true,
		enableScrollSpy: true,
		prefix,
		suffix,
	});

	useEffect(() => {
		if (countUpRef) start();
	}, [countUpRef, start]);

	return (
		<div className={s._NumerItem}>
			<div className={s._Square}>
				<div className={s._NumberHeader}>
					<div className={s._TitleWrapper}>
						<div className={s._Icon}>
							<Icon.IcArrowCircul />
						</div>
						<div className={s._HeaderText}>
							<span ref={countUpRef}>{0}</span>
							<span className={s._Small}> {unit}</span>
						</div>
					</div>
				</div>
				<p className={s._DescriptionText}>{description}</p>
			</div>
		</div>
	);
};

export default HomeFutureItem;
