import clsx from "clsx";
import s from "./NewsSingleContentTextSection.module.scss";

const NewsSingleContentTextSection = ({ title, description }) => {
	return (
		<div className={clsx(s._Wrapper)}>
			<h2 className={s._Title}>{title}</h2>
			<p className={s._Description} dangerouslySetInnerHTML={{ __html: description }}></p>
		</div>
	);
};

export default NewsSingleContentTextSection;
