import Dotts from "@/components/atoms/Svgs/Dotts";
import Event from "@/components/atoms/Svgs/Event";
import Hydro from "@/components/atoms/Svgs/Hydro";
import Logo from "@/components/atoms/Svgs/Logo";
import CardNews from "@/components/molecules/CardNews";
import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import "@/css/news.css";
import "@/css/style.css";
import { arrNewsData } from "@/data/news.data";
import AOS from "aos";
import clsx from "clsx";
import { useEffect, useState } from "react";
import s from "./News.module.scss";

export default function News() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		AOS.init({
			duration: 800,
			offset: 0,
			easing: "ease",
		});
	}, []);
	const [selectedCategory, setSelectedCategory] = useState("All news"); // Default filter category

	const handleCategoryChange = (category) => {
		setSelectedCategory(category);
	};

	const filteredCardItems = arrNewsData.filter((item) => {
		if (selectedCategory === "All news" || item.newsCategories[0] === selectedCategory) {
			return true;
		}
		return false;
	});

	useEffect(() => {
		const categories__buttons = document.querySelectorAll(".categories__button");
		categories__buttons.forEach((button) => {
			button.addEventListener("click", () => {
				for (let ind = 0; ind < categories__buttons.length; ind++) {
					const bbtn = categories__buttons[ind];
					bbtn.classList.remove("active");
				}
				button.classList.add("active");
				const category = button.querySelector("span").innerText;
				handleCategoryChange(category);
			});
		});
	}, []);

	return (
		<>
			<Header isScrolledInit />
			<div className="news">
				<div className="container">
					<h2 className={s._NewsHead__Title}>
						Ghyser News<span className="green-dot">.</span>
					</h2>
					<div className={s._NewsHead__Description}>
						Explore our last news, how-to articles, and Ghyser updates
					</div>
					<div className="news__categories categories">
						<ul className="categories__list">
							<li className="categories__item">
								<button className="categories__button categories__button_dott active">
									<i>
										<Dotts />
									</i>
									<span>All news</span>
								</button>
							</li>
							<li className="categories__item">
								<button className="categories__button">
									<i>
										<Logo />
									</i>
									<span>Ghyser</span>
								</button>
							</li>
							<li className="categories__item">
								<button className="categories__button">
									<i>
										<Hydro />
									</i>
									<span>Hydrogen</span>
								</button>
							</li>
							<li className="categories__item">
								<button className="categories__button">
									<i>
										<Event />
									</i>
									<span>Events</span>
								</button>
							</li>
						</ul>
						<div className="categories__cards categories-cards">
							<ul className={clsx("categories-cards__list", s._CardWrapper)}>
								{filteredCardItems.map((item, i) => {
									return (
										<CardNews
											newsName={item.newsShortName}
											newsCategories={item.newsCategories}
											newsPostDate={item.newsPostDate}
											newsSlug={item.newsSlug}
											newsTag={item.newsTag}
											newsThumbnail={item.newsThumbnail}
										/>
										// <li key={i} className="categories-cards__item happening-item">
										// 	<div className="categories-cards__picture happening-item__picture _ibg">
										// 		<div className="happening-item__picture-layer"></div>
										// 		<img src={item.th} alt={item.title} />
										// 	</div>
										// 	<div className="happening-item__row">
										// 		<div className="happening-item__layer"></div>
										// 		<div className="happening-item__context happening-item-context">
										// 			<div className="happening-item-context__row">
										// 				<div className="categories-cards__title happening-item-context__title">
										// 					{item.title}
										// 				</div>
										// 				<a href="#" title="Coming soon" className="categories-cards__bbtn bbtn">
										// 					<i className="_icon-arrow-mini"></i>
										// 				</a>
										// 			</div>
										// 			<div className="categories-cards__text happening-item-context__text">
										// 				{item.text}
										// 			</div>
										// 			<div className="categories-cards__footer happening-item-context__footer">
										// 				<div className="categories-cards__news happening-item-context__sub-title">
										// 					Daily News
										// 				</div>
										// 				<div className="categories-cards__date happening-item-context__date">
										// 					{item.date}
										// 				</div>
										// 			</div>
										// 		</div>
										// 	</div>
										// </li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
