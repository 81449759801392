import MouseFollowSVG from "@/components/atoms/MouseFollowSvg";
import clsx from "clsx";
import s from "./ImageHeader.module.scss";

/**
 * @component
 * @param {Object} props
 * @property {string} imageData
 * @property {string} title
 * @property {string} subTitle
 * @returns {JSX.Element}
 */

const ImageHeader = ({ imageData, title, subTitle }) => {
	return (
		<div className={s._Wrapper}>
			<img src={imageData} alt={title} className={s._Image} />
			<div className={clsx(s._HeroContent, "container")}>
				<MouseFollowSVG />
				<h1 className={s._HeroTitle}>
					{title}
					<span>.</span>
				</h1>
				<p className={s._HeroSubTitle}>{subTitle}</p>
			</div>
		</div>
	);
};

export default ImageHeader;
