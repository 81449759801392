import { Icon } from "@/components/atoms/Icons";
import clsx from "clsx";
import { useState } from "react";
import s from "./TransitonPillTabs.module.scss";

/**
 * @typedef {object} TransitonPillTabsProps
 * @property {array} items
 *
 * @param {TransitonPillTabsProps} param1
 */
const TransitonPillTabs = ({ items }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className={clsx("container", s._Wrapper)}>
      <div className={s._Tabs}>
        {items.map((item, index) => (
          <div
            key={index}
            onClick={() => handleTabClick(index)} // Use a callback here
            className={clsx(s._Tab, index === activeTab && s._Active)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className={s._Content}>
        {items.map((item, index) => (
          <div
            key={index}
            className={clsx(s._Item, index === activeTab && s._Active)}
          >
            <div className={s._ItemLeft}>
              <div className={s._ItemTitle}>{item.title}</div>
              <hr className={s._ItemLine} />
              <div className={s._ItemDescription}>{item.text}</div>
              <div className={s._CTA}>
                <a href="/contact" className={clsx(s._Button, index === activeTab && s._ButtonActive)}>
                  <p className={s._Text}>Get in touch</p>
                  <Icon.IcArrowRightLong />
                </a>
              </div>
            </div>
            <div className={s._ItemRight}>
              <img
                className={s._Image}
                src={item.img}
                alt={item.title}
                height={513}
                width={829}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransitonPillTabs;
