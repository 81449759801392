import { ReactComponent as IcArrowCircul } from "./ic-arrow-circul.svg";
import { ReactComponent as IcArrowRightLong } from "./ic-arrow-right-long.svg";
import { ReactComponent as IcArrowTopRight } from "./ic-arrow-top-right.svg";
import { ReactComponent as IcArrowTop } from "./ic-arrow-top.svg";
import { ReactComponent as IcDiscord } from "./ic-discord.svg";
import { ReactComponent as IcSlideArrowLeft } from "./ic-slide-arrow-left.svg";
import { ReactComponent as IcSlideArrowRight } from "./ic-slide-arrow-right.svg";
import { ReactComponent as IcLinkedin } from "./linkedin.svg";
import { ReactComponent as IcXFill } from "./x-fill.svg";

export const Icon = {
	IcArrowTopRight: IcArrowTopRight,
	IcArrowRightLong: IcArrowRightLong,
	IcSlideArrowLeft: IcSlideArrowLeft,
	IcSlideArrowRight: IcSlideArrowRight,
	IcArrowTop: IcArrowTop,
	IcArrowCircul: IcArrowCircul,
	IcLinkedin: IcLinkedin,
	IcXFill: IcXFill,
	IcDiscord: IcDiscord,
};
