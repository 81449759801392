import PoweringVideo from "@/components/PoweringVideo";
import ImageHeader from "@/components/atoms/ImageHeader";
import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import "@/css/news.css";
import "@/css/style.css";
import PillarItem from "@/features/home/components/HomeTransition/PillarItem";
import { PillarItems } from "@/features/home/components/HomeTransition/PillarItems.data";
import ServicesIcon from "@/img/about/Services.svg";
import SustainabilityIcon from "@/img/about/Sustainability.svg";
import VisionIcon from "@/img/about/vision.svg";
import heroBg from "@/img/atf/Our process.webp";
import CeoCircul from "@/img/home/CEO-cricul.webp";
import AOS from "aos";
import { useEffect } from "react";
import s from "./HowWeWork.module.scss";

export default function HowWeWork() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const contentItems = [
		{
			title: "Efficiency boost with floating solar panels ",
			content:
				"Our floating solar panels achieve 30% higher efficiency, yielding more power with fewer resources.",
			icon: VisionIcon,
		},
		{
			title: "Strategic offshore location",
			content:
				"Located at sea, we’re positioned directly above abundant raw materials for efficient energy production.",
			icon: ServicesIcon,
		},
		{
			title: "Unlimited renewable resource access",
			content:
				"We harness the endless potential of renewable resources, ensuring sustainable energy generation for the future.",
			icon: SustainabilityIcon,
		},
	];

	useEffect(() => {
		AOS.init({
			duration: 800,
			offset: 0,
			easing: "ease",
		});
	}, []);
	return (
		<>
			<Header isScrolledInit />
			<div className="vision">
				<ImageHeader title="Our Process" imageData={heroBg} />
				<div className={s._Pillers}>
					<h2>
						The ghyser pillars<span>.</span>
					</h2>
					<div className={s._Pillers__Content}>
						{PillarItems.map((item, idx) => {
							return <PillarItem img={item.img} title={item.title} description={item.text} />;
						})}
					</div>
				</div>
				<div className={s._ContentWrapper}>
					<div className={s._Content}>
						<div className={s._ContentLeft}>
							{contentItems.map((item, index) => (
								<div key={index} className={s._ContentItem}>
									<div className={s._ContentItemIcon}>
										<img src={item.icon} alt="icon" />
										<h3>{item.title}</h3>
									</div>
									<div className={s._ContentItemText}>
										<p>{item.content}</p>
									</div>
								</div>
							))}
						</div>
						<div className={s._ContentRight}>
							<h2>
								Superiority at sea<span>.</span>
							</h2>
							<p className={s._ContentRightText}>
								At Ghyser, we are motivated by the vision of building a sustainable energy future.
								We believe offshore green hydrogen can make this vision a reality by leveraging the
								vast renewable resources of the oceans. Our goal is to pioneer clean hydrogen
								production at a scale that accelerates decarbonization globally. We are driven to
								apply innovative thinking in pursuit of meaningful technology advances that increase
								adoption. We measure our success not only in economic terms but also by the local
								capacity we build and emissions we displace. <br />
								<br />
								Let’s light the path toward a net zero future fueled by green hydrogen.
							</p>
							<div className={s._ContentRightVideo}>
								<PoweringVideo />
							</div>
						</div>
					</div>
				</div>

				<div className={s._CEOWrapper}>
					<div className={s._Image}>{/* <PoweringVideo /> */}</div>
					<div className={s._CEOContent}>
						<div className={s._CEOContentText}>
							<p>
								“At Ghyser, we envision a future where renewable energy reigns supreme, and green
								hydrogen becomes the driving force of sustainability. With expertise in frequency
								physics and Al algorithms,{" "}
								<span>
									we lead the charge in innovative offshore solutions, shaping a world powered by
									clean, efficient, and carbon- neutral energy.
								</span>
								”
							</p>
						</div>
						<div className={s._CEOContentImage}>
							<div>
								<img src={CeoCircul} alt="CEO" className={s._Image} />
							</div>
							<div>
								<p>Christopher Adams</p>
								<p>
									<span>Co-Founder / CEO</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
