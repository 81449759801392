import CardNews from "@/components/molecules/CardNews";
import { PATHS } from "@/constants/PATHS";
import Aos from "aos";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { A11y, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import s from "./SliderNewsContainer.module.scss";

/**
 *
 * @typedef {object} NewsSlideProps
 * @property {Array} newsData
 * @property {string} [className]
 *
 * @param {NewsSlideProps} param1
 */

const SliderNewsContainer = ({ newsData, className }) => {
	const [isArrowReady, setIsArrowReady] = useState(false);
	const [sliderEL, setSliderEL] = useState(null);
	const [activeIndex, setActiveIndex] = useState(1);
	const [totalPagination, setTotalPagination] = useState(1);
	const [screenSize, setScreenSize] = useState(0);

	useEffect(() => {
		Aos.init({ duration: 1000, offset: 50, easing: "ease" });
	}, []);

	const buttonPrevRef = useRef(null);
	const buttonNextRef = useRef(null);

	useEffect(() => {
		if (!buttonPrevRef && !buttonNextRef) return;
		setIsArrowReady(true);
	}, [buttonPrevRef, buttonNextRef]);

	useEffect(() => {
		if (!sliderEL) return;
		const slidePerView = sliderEL.params.slidesPerView;
		const totalSlides = sliderEL.slides.length;
		const totalPagination = totalSlides - slidePerView;
		setTotalPagination(totalPagination >= 1 ? totalPagination + 1 : 1);
	}, [sliderEL]);

	useEffect(() => {
		setScreenSize(window.innerWidth);
	}, []);

	if (!screenSize) return;

	return (
		<div className={clsx(s._Wrapper, className)}>
			<div className={clsx("container")}>
				<div className={clsx(s._Head)}>
					<h2>
						What’s Happening At Ghyser<span>.</span>
					</h2>
					<div className={clsx(s._ButtonWrapper)}>
						<p>
							<span>{activeIndex.toString().padStart(2, "0")}</span>{" "}
							<span>/ {totalPagination.toString().padStart(2, "0")}</span>
						</p>
						<button ref={buttonPrevRef} className={clsx(s._SliderButton, s._Prev)}>
							<svg
								width="100%"
								height="100%"
								viewBox="0 0 60 60"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect
									x="-0.5"
									y="0.5"
									width="55"
									height="55"
									rx="27.5"
									transform="matrix(-1 0 0 1 57 2)"
									stroke="currentColor"
								/>
								<path
									d="M24.1443 22.6958L25.4107 23.8212L20.0376 28.5957H43.3916V30.1873H20.0377L25.4107 34.9617L24.1443 36.0871L16.609 29.3915L24.1443 22.6958Z"
									fill="currentColor"
								/>
							</svg>
						</button>
						<button ref={buttonNextRef} className={clsx(s._SliderButton, s._Next)}>
							<svg
								width="100%"
								height="100%"
								viewBox="0 0 60 60"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect x="2.5" y="2.5" width="55" height="55" rx="27.5" stroke="currentColor" />
								<path
									d="M35.8557 22.6958L34.5893 23.8212L39.9624 28.5957H16.6084V30.1873H39.9623L34.5893 34.9617L35.8557 36.0871L43.391 29.3915L35.8557 22.6958Z"
									fill="currentColor"
								/>
							</svg>
						</button>
					</div>
				</div>
				<div className={clsx(s._SliderWrapper)}>
					{isArrowReady && (
						<Swiper
							slidesPerView={3}
							spaceBetween={56}
							modules={[A11y, Navigation, Pagination]}
							navigation={{
								prevEl: buttonPrevRef?.current,
								nextEl: buttonNextRef?.current,
							}}
							onSwiper={setSliderEL}
							onTransitionStart={(swiper) => setActiveIndex(swiper?.activeIndex + 1 || 1)}
							breakpoints={{
								1700: {
									spaceBetween: 56,
								},
								1100: {
									spaceBetween: 38.5,
								},
								600: {
									slidesPerView: 2,
									spaceBetween: 15,
								},
								0: {
									slidesPerView: 1,
									spaceBetween: 15,
								},
							}}
						>
							{newsData &&
								newsData?.length &&
								newsData.map((item, key) => (
									<SwiperSlide key={key}>
										<CardNews
											newsName={item?.newsShortName}
											newsCategories={item?.newsCategories}
											newsSlug={`${PATHS.news}/${item?.newsSlug}`}
											newsTag={item?.newsTag}
											newsPostDate={item?.newsPostDate}
											newsThumbnail={item?.newsThumbnail}
										/>
									</SwiperSlide>
								))}
							{screenSize > 1099 && <SwiperSlide></SwiperSlide>}
						</Swiper>
					)}
				</div>
				<div className={clsx(s._Foot)}>
					<Link to={"/news"} className={clsx(s._ButtonSeeAll)}>
						View all News{" "}
						<svg
							width="100%"
							height="100%"
							viewBox="0 0 56 56"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle cx="28" cy="28" r="28" fill="currentColor" />
							<path
								d="M22.6249 31.9596L21.9178 32.6667L23.332 34.081L24.0391 33.3739L22.6249 31.9596ZM34.2315 22.7673C34.2315 22.215 33.7838 21.7673 33.2315 21.7673L24.2315 21.7673C23.6792 21.7673 23.2315 22.215 23.2315 22.7673C23.2315 23.3195 23.6792 23.7673 24.2315 23.7673L32.2315 23.7673L32.2315 31.7673C32.2315 32.3195 32.6792 32.7673 33.2315 32.7673C33.7838 32.7673 34.2315 32.3195 34.2315 31.7673L34.2315 22.7673ZM24.0391 33.3739L33.9386 23.4744L32.5244 22.0601L22.6249 31.9596L24.0391 33.3739Z"
								fill="white"
							/>
						</svg>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SliderNewsContainer;
