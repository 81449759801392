import clsx from "clsx";
import s from "./BadgePill.module.scss";

/**
 * @component
 * @param {Object} props
 * @property {string} [className]
 * @property {ReactNode} children
 * @returns {JSX.Element}
 */

const BadgePill = ({ children, className }) => {
	return <p className={clsx(s._Wrapper, className)}>{children}</p>;
};

export default BadgePill;
