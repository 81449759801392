import useBrowser from "@/hooks/useBrowser";

const PoweringVideo = (props) => {
	const browser = useBrowser();

	return (
		<div
			className="video"
			dangerouslySetInnerHTML={{
				__html: `
			  <video
			    loop
			    muted
			    autoplay
			    playsInline
			    src="/assets/video/powering-a-greener.${browser === "Apple Safari" ? "mp4" : "webm"}"
			  ></video>
			`,
			}}
		></div>
	);
};

export default PoweringVideo;
