import img3 from "@/img/industries/agriculture.png";
import img1 from "@/img/industries/aviation.png";
import img4 from "@/img/industries/energy-storage.png";
import img7 from "@/img/industries/industrial.png";
import img5 from "@/img/industries/marine-transport.png";
import img2 from "@/img/industries/oil-gas.png";
import img6 from "@/img/industries/trucking.png";

export const slideItems = [
	{
		title: "Aviation",
		text: "Ghyser offers aviation companies a unique chance to accelerate decarbonization by adopting its offshore-produced green hydrogen and oxygen, enabling emissions reductions and potential transitions to hydrogen-electric engines.",
		link: "Read More",
		img: img1,
	},
	{
		title: "Oil & Gas Industry",
		text: "Ghyser's offshore green hydrogen and oxygen production will aid the oil and gas industry in achieving sustianbility goals, reducing carbon footprints, and meeting national net-zero targets while ensuring future-proofing amid tightening carbon policies.",
		link: "Read More",
		img: img2,
	},
	{
		title: "Agriculture",
		text: "Ghyser's green ammonia provides carbon-free fertilizer to enable sustainable food production.",
		link: "Read More",
		img: img3,
	},
	{
		title: "Energy Storage",
		text: "Ghyser enables grid-scale renewable energy storage using green hydrogen.",
		link: "Read More",
		img: img4,
	},
	{
		title: "Marine Transport",
		text: "Ships can eliminate emissions with Ghyser's carbon-free green hydrogen and ammonia bunkering solutions.",
		link: "Read More",
		img: img5,
	},
	{
		title: "Trucking",
		text: "Ghyser provides renewable hydrogen for fuel cell electric trucks to transition goods transport to zero-emissions.",
		link: "Read More",
		img: img6,
	},
	{
		title: "Industrial",
		text: "Manufacturers can use Ghyser's green hydrogen to cut emissions in steel, chemical and cement production.",
		link: "Read More",
		img: img7,
	},
];
