import { Icon } from "../Icons";
import s from "./ArrowButton.module.scss";

const ArrowButton = ({ text = "Button", href = "/" }) => {
	return (
		<a href={href} className={s._Button}>
			<span className={s._Text}>{text}</span>
			<span className={s._ArrowButton}>
				<div className={s._Icon}>
					<Icon.IcArrowTopRight color="white" />
				</div>
			</span>
		</a>
	);
};

export default ArrowButton;
