import ImageHeader from "@/components/atoms/ImageHeader";
import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import "@/css/news.css";
import "@/css/style.css";
import heroBg from "@/img/atf/Vision.webp";
import CEOPic from "@/img/vision/ceo.webp";
import AOS from "aos";
import { useEffect } from "react";
import s from "./Vision.module.scss";

export default function Vision() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		AOS.init({
			duration: 800,
			offset: 0,
			easing: "ease",
		});
	}, []);
	return (
		<>
			<Header isScrolledInit />
			<div className="vision">
				<ImageHeader title="Vision of the ceo" imageData={heroBg} />
				<div className={s._ContentWrapper}>
					<div className={s._Content}>
						<div className={s._ContentLeft}>
							<img src={CEOPic} alt="Vision of the ceo" className={s._Image} />
						</div>
						<div className={s._ContentRight}>
							<h2>
								Think globally, innovate locally, for the future of our planet<span>.</span>
							</h2>
							<p className={s._ContentRightText}>
								With over 30 years of experience working with leading global companies and numerous
								patents to my name, I have developed a breakthrough technology that can unlock the
								full potential of green hydrogen. <br />
								<br />
								I am particularly proud to have been recognized by Bill Gates with a prestigious
								award for my innovations. This honor from one of the world's leading technologists
								and philanthropists demonstrates the immense potential of my work.
								<br />
								<br />
								The need for clean, renewable energy solutions has never been greater. With climate
								change accelerating and
								<br />
								geopolitical tensions rising, the world needs sustainable energy sources that can
								meet our needs while respecting the planet. Green hydrogen presents an immense
								opportunity, but its growth has been hindered by inefficient and expensive
								production methods.
								<br />
								<br />
								This is where Ghyser comes in.
								<br />
								<br />
								Our novel approach repurposes decommissioned offshore oil rigs, giving these
								facilities new life generating affordable green hydrogen. By achieving unparalleled
								efficiency, we outperform anything on the market today. Rigorous tests validate
								these sizable gains, proving this technology's readiness for rapid scale.
								<br />
								<br />
								The addressable market is monumental, as green hydrogen transitions to a primary
								energy backbone powering the renewable economy. The future of energy is here - let's
								seize it in an environmentally responsible way.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}
