import { useEffect, useState } from "react";

function MouseFollowSVG() {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

	useEffect(() => {
		const handleMouseMove = (e) => {
			setMousePosition({ x: e.clientX, y: e.clientY });
		};

		document.addEventListener("mousemove", handleMouseMove);

		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
		};
	}, []);

	const center = { x: 127 / 2, y: 127 / 2 };

	const deltaX = mousePosition.x - center.x;
	const deltaY = mousePosition.y - center.y;
	const rotationAngle = (Math.atan2(deltaY, deltaX) * 360) / Math.PI - 50;

	return (
		<svg
			className="desktop-arrow"
			xmlns="http://www.w3.org/2000/svg"
			width="127"
			height="127"
			viewBox="0 0 127 127"
			fill="none"
			style={{
				transformOrigin: `${center.x}px ${center.y}px`,
				transform: `rotate(${rotationAngle}deg)`,
			}}
		>
			<path
				d="M29.1475 26.7188L96.3481 93.9194M96.3481 93.9194V29.4068M96.3481 93.9194H31.8355"
				stroke="#1EB66E"
				strokeWidth="7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default MouseFollowSVG;
