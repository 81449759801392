import { Icon } from "@/components/atoms/Icons";
import clsx from "clsx";
import { useSwiper } from "swiper/react";
import s from "./ItemSlide.module.scss";

/**
 * @typedef {object} ItemSlideProps
 * @property {string} title
 * @property {string} img
 * @property {string} text
 * @property {number} idx
 * @property {number} total
 *
 * @param {ItemSlideProps} param1
 */
const ItemSlide = ({ img, title, text, idx, total }) => {
	const swiper = useSwiper();

	return (
		<>
			<div className={s._SwiperItem}>
				<div className={s._Image}>
					<img src={img} alt="" className="pictures" />
				</div>
				<div className={s._SwiperItemTitle}>{title}</div>
				<div className={s._SwiperItemText}>{text}</div>
			</div>

			<div className={s._Navigation}>
				<button
					className={clsx(s._Navigation__Button, idx === 1 ? s["_Navigation--Disabled"] : "")}
					onClick={() => swiper.slidePrev()}
				>
					<Icon.IcSlideArrowLeft />
				</button>
				<button
					className={clsx(s._Navigation__Button, idx === total ? s["_Navigation--Disabled"] : "")}
					onClick={() => swiper.slideNext()}
				>
					<Icon.IcSlideArrowRight />
				</button>
			</div>
		</>
	);
};

export default ItemSlide;
