import CeoCircul from "@/img/home/CEO-cricul.webp";
import AOS from "aos";
import clsx from "clsx";
import { useEffect } from "react";
import s from "./HomePowering.module.scss";

function HomePowering() {
	useEffect(() => {
		AOS.init({
			duration: 500,
			offset: 50,
			easing: "ease-out",
		});
	});

	return (
		<div className={clsx(s._Wrapper)}>
			<div className={clsx("container")}>
				<h2 className={clsx(s._Title)}>
					POWERING A <span>GREENER</span> TOMORROW<span>.</span>
				</h2>
				<div className={clsx(s._ContentWrapper)}>
					<div className={clsx(s._TextWrapper)}>
						<div className={s._CeoBlock}>
							<img src={CeoCircul} alt="CEO" className={clsx(s._Image)} />
						</div>

						<p className={clsx(s._Description)}>
							“At Ghyser, we envision a future where renewable energy reigns supreme, and green
							hydrogen becomes the driving force of sustainability. With expertise in frequency
							physics and Al algorithms,{" "}
							<span>
								we lead the charge in innovative offshore solutions, shaping a world powered by
								clean, efficient, and carbon- neutral energy.
							</span>
							”
							<span className={s._Description__Ceo}>
								<span>Christopher Adams</span>
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default HomePowering;
