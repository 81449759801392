import SliderNewsContainer from "@/components/organisms/SliderNewsContainer";
import { arrNewsData } from "@/data/news.data";
import clsx from "clsx";
import s from "./HomeWhatHappening.module.scss";

const HomeWhatHappening = () => {
	return (
		<div className={clsx(s._Wrapper)}>
			<SliderNewsContainer newsData={arrNewsData} />
		</div>
	);
};

export default HomeWhatHappening;
