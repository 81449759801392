import clsx from "clsx";
import s from "./PillarItem.module.scss";

/**
 * @typedef {object} PillarItemProps
 * @property {string} img
 * @property {string} title
 * @property {string} description
 *
 * @param {PillarItemProps} param1
 */
const TransitionSlideItem = ({ img, title, description }) => {
	return (
		<div className={s._Wrapper}>
			<div className={s._Content}>
				<img className={s._Image} src={img} alt={title} height={136} width={136} />
				<p className={clsx("black-0", s._Content__Title)}>{title}</p>
			</div>
			<p className={clsx("black-0", s._Content__Description)}>{description}</p>
		</div>
	);
};

export default TransitionSlideItem;
