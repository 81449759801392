import Contact from "@/features/contact/view";
import Home from "@/features/home/view";
import Investors from "@/features/investors/view";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { PATHS } from "./constants/PATHS";
import "./css/fonts.css";
import AboutUs from "./features/about-us/view";
import HowWeWork from "./features/how-we-work/view";
import News from "./features/news/view";
import NewsSingle from "./features/newsSingle/view";
import Vision from "./features/vision/view";
import "./scss/globals.scss";

const App = () => {
	return (
		<div className="App">
			<Router>
				<main className="main">
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/news" element={<News />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/investors" element={<Investors />} />
						<Route path="/vision" element={<Vision />} />
						<Route path="/about-us" element={<AboutUs />} />
						<Route path="/our-process" element={<HowWeWork />} />
						<Route path={`${PATHS.news}/:newsSlug`} element={<NewsSingle />} />
					</Routes>
				</main>
			</Router>
		</div>
	);
};

export default App;
