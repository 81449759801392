import ImageHeader from "@/components/atoms/ImageHeader";
import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import ContactForm from "@/features/contact/components/ContactForm";
import heroBg from "@/img/atf/Contact us.webp";
import { useEffect } from "react";

export default function Contact() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			<ImageHeader title={`Clean Hydrogen Energy at scale. Get in Touch`} imageData={heroBg} />
			<ContactForm />
			<Footer />
		</>
	);
}
