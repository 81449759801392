import React from 'react'

export default function Dotts() {
  return (
   <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.26172 8.25H3.51172C2.40672 8.25 1.51172 7.355 1.51172 6.25V3.5C1.51172 2.395 2.40672 1.5 3.51172 1.5H6.26172C7.36672 1.5 8.26172 2.395 8.26172 3.5V6.25C8.26172 7.355 7.36672 8.25 6.26172 8.25Z" fill="black" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5117 8.25H14.7617C13.6567 8.25 12.7617 7.355 12.7617 6.25V3.5C12.7617 2.395 13.6567 1.5 14.7617 1.5H17.5117C18.6167 1.5 19.5117 2.395 19.5117 3.5V6.25C19.5117 7.355 18.6167 8.25 17.5117 8.25Z" fill="black" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M6.26172 19.5H3.51172C2.40672 19.5 1.51172 18.605 1.51172 17.5V14.75C1.51172 13.645 2.40672 12.75 3.51172 12.75H6.26172C7.36672 12.75 8.26172 13.645 8.26172 14.75V17.5C8.26172 18.605 7.36672 19.5 6.26172 19.5Z" fill="black" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17.5117 19.5H14.7617C13.6567 19.5 12.7617 18.605 12.7617 17.5V14.75C12.7617 13.645 13.6567 12.75 14.7617 12.75H17.5117C18.6167 12.75 19.5117 13.645 19.5117 14.75V17.5C19.5117 18.605 18.6167 19.5 17.5117 19.5Z" fill="black" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
   </svg>
  )
}
