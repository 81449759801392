import img3 from "@/img/pillars/Commercially-1.png";
import img1 from "@/img/pillars/Off-shore-1.png";
import img2 from "@/img/pillars/sustainable-1.png";

export const PillarItems = [
	{
		title: "Off-shore Green Hydrogen",
		text: "Integrating electrolysis directly with offshore wind and floating solar",
		img: img1,
	},
	{
		title: "Fully sustainable",
		text: "Powered by renewable wind and solar energy",
		img: img2,
	},
	{
		title: "Commercially scalable production",
		text: "Designed for incremental scaling up of green hydrogen output",
		img: img3,
	},
];
