import Aos from "aos";
import clsx from "clsx";
import { useEffect } from "react";
import { slideItems } from "./HomeTransition.data";
import s from "./HomeTransition.module.scss";
import PillarItem from "./PillarItem";
import { PillarItems } from "./PillarItems.data";
import TransitonPillTabs from "./TransitonPillTabs";

const HomeTransition = () => {
	useEffect(() => {
		Aos.init({ duration: 1000, offset: 50, easing: "ease" });
	}, []);

	return (
		<div className={s._Wrapper} id="industries">
			<div className={clsx("container", s._Head)}>
				<h2 className={clsx("h3 uppercase neutral-100", s._Head__Title)} data-aos-delay={100}>
					Transition to renewable energy<i className="primary">.</i>
				</h2>
				<p
					className={clsx("body-md neutral-100", s._Head__Description)}
					data-aos="fade-left"
					data-aos-delay={200}
				>
					Companies are turning to Ghyser's renewable hydrogen and oxygen to cut emissions and meet
					sustainability goals, thanks to its offshore renewable production model.
				</p>
			</div>
			<TransitonPillTabs items={slideItems} />
			<div className={s._Footer}>
				<h2 className={clsx("h3 uppercase")}>
					The {/* <span> */}
					gh
					{/* <span className={s._spaceBetween}>
							<span className={s._macht}>2</span>
						</span> */}
					{/* </span> */}
					yser pillars<span>.</span>
				</h2>
				<div className={s._Footer__Content}>
					{PillarItems.map((item, idx) => {
						return <PillarItem img={item.img} title={item.title} description={item.text} />;
					})}
				</div>
			</div>
		</div>
	);
};

export default HomeTransition;
