import clsx from "clsx";
// @ts-ignore
import pic2_1 from "@/img/industries/oil-gas.webp";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import pic1 from "../../assets/Group 1000004016.webp";
import pic2 from "../../assets/Group 1000004017.webp";
import pic3 from "../../assets/Group 1000004018.webp";
import pic4 from "../../assets/Group 1000004019.webp";
import pic5 from "../../assets/Group 1000004020.webp";
import s from "./HomeOurExperience.module.scss";
import ItemSlide from "./ItemSlide";

const items = [
	{
		title: "Renewable Energy Sources",
		img: pic1,
		text: "Ghysers Green Hydrogen production utilizes offshore platforms equipped with wind turbines and solar panels, capturing clean energy from nature.",
	},
	{
		title: "Decommissioned Oil Rigs",
		img: pic2_1,
		text: "Ghyser platforms are made using decommisioned oil rigs, allowing us to drastically cut down on construction costs and time while reducing our carbon footprint",
	},
	{
		title: "Efficient hydrogen storage",
		img: pic2,
		text: "Produced Green Hydrogen is efficiently compressed and stored within our off-shore platform ensuring reliable storage capacity",
	},
	{
		title: "Carbon Neutral Process",
		img: pic3,
		text: "The entire production process is carbon-neutral since the renewable energy used, offsets any emissions associated with hydrogen production.",
	},
	{
		title: "Electrolysis Technology",
		img: pic4,
		text: "State-of-the-art electrolyzers on the platform use renewable electricity to split water into hydrogen and oxygen through electrolysis.",
	},
	{
		title: "Transportation and Utilization",
		img: pic5,
		text: "Hydrogen can be transported through pipelines or in the form of compressed hydrogen or ammonia for export or local distribution and utilized across various industries.",
	},
];

const HomeOurExperience = () => {
	return (
		<div className={s._Wrapper} id="solution">
			<div className={clsx(s._Container, "container")}>
				<h2 className={s._Title}>
					Experience on a global scale<span>.</span>
				</h2>
				<ul className={s._ExperienceList}>
					{items.map((item, index) => (
						<li
							key={index}
							className={s._ExperienceItem}
							onMouseMove={(e) => {
								const liRect = e.currentTarget.getBoundingClientRect();
								const picture = e.currentTarget.querySelector(".pictures");
								// @ts-ignore
								const pictureWidth = picture.offsetWidth;
								// @ts-ignore
								const pictureHeigth = picture.offsetHeight;
								const offsetX = e.clientX - liRect.left - pictureWidth / 2;
								const offsetY = e.clientY - liRect.top - pictureHeigth / 2;
								if (window.innerWidth > 767) {
									// @ts-ignore
									picture.style.transform = `translate(${offsetX}px, ${offsetY}px )`;
									// @ts-ignore
									picture.style.opacity = "1";
									document.body.style.cursor = "none";
								}
							}}
							onMouseOut={(e) => {
								document.body.style.cursor = "default";
								const picture = e.currentTarget.querySelector(".pictures");
								// @ts-ignore
								picture.style.transform = "translate(0)";
								// @ts-ignore
								picture.style.opacity = "0";
							}}
						>
							<img src={item.img} alt="" className={clsx("pictures", s._ItemImage)} />
							<div className={s._ItemTitle} style={{ zIndex: 1 }}>
								{item.title}
							</div>
							<div className={s._ItemText} style={{ zIndex: 1 }}>
								{item.text}
							</div>
						</li>
					))}
				</ul>
				<Swiper className={s._ExperienceSwiper} slidesPerView={1} spaceBetween={50}>
					{items.map((item, ind) => (
						<SwiperSlide className={s._ExperienceSlide} key={ind}>
							<ItemSlide
								idx={ind + 1}
								title={item.title}
								img={item.img}
								text={item.text}
								total={items.length}
							/>
						</SwiperSlide>
					))}
				</Swiper>
			</div>
		</div>
	);
};

export default HomeOurExperience;
