import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HomeFuture from "../components/HomeFuture";
import HomeGreenHydrogen from "../components/HomeGreenHydrogen/HomeGreenHydrogen.component";
import HomeHeroBanner from "../components/HomeHeroBanner";
import HomeOurExperience from "../components/HomeOurExperience/HomeOurExperience.component";
import HomePowering from "../components/HomePowering";
import HomeTeam from "../components/HomeTeam";
import HomeTransition from "../components/HomeTransition";
import HomeWhatDrivesUs from "../components/HomeWhatDrivesUs";
import HomeWhatHappening from "../components/HomeWhatHappening";

const Home = () => {
	const { hash } = useLocation();
	useEffect(() => {
		if (!hash) {
			window.scrollTo(0, 0);
		} else {
			document.querySelector(hash).scrollIntoView({ behavior: "smooth" });
		}
	}, [hash]);

	return (
		<>
			<Header />
			<HomeHeroBanner />
			<HomeTransition />
			<HomeWhatDrivesUs />
			<HomeFuture />
			{/* <HomeFutureOfGreenEnergy /> */}
			<HomeOurExperience />
			<HomePowering />
			<HomeGreenHydrogen />
			<HomeTeam />
			<HomeWhatHappening />
			<Footer />
		</>
	);
};

export default Home;
