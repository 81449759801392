import clsx from "clsx";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import s from "./ContactForm.module.scss";

const ContactForm = () => {
	const [showSuccessMessage, setShowSuccessMessage] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const [recaptchaValue, setRecaptchaValue] = useState(null);
	const handleContactFormSubmit = async (e) => {
		e.preventDefault();
		if (recaptchaValue) {
			const formSubmitedData = new FormData(e.target);
			const jsonSubmitedData = Object.fromEntries(formSubmitedData);
			const headers = new Headers();
			headers.append("Content-Type", "application/json");
			const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact`, {
				method: "POST",
				headers,
				body: JSON.stringify(jsonSubmitedData),
			});
			const jsonResponse = await response.json();

			if (jsonResponse.success) {
				setShowSuccessMessage(true);
				setTimeout(() => {
					window.location.reload();
				}, 5000); // You can adjust the time delay as needed
			} else {
				setShowErrorMessage(true);
			}
		} else {
			setShowErrorMessage(true);
		}
	};
	return (
		<div className={clsx(s._Wrapper)} id="contactForm">
			<div className={clsx("container")}>
				<div className={s._ContentLeft}>
					<h2>
						Tell us what you need<span>.</span>
					</h2>
					<p>
						No matter which field of our industry you are interested in, or in which country you are
						located, we are here to support you. Please use the contact form below to raise your
						question. You can also send us an e-mail to{" "}
						<a href="mailto:info@ghyser.com">info@ghyser.com</a>.
					</p>
				</div>
				<form onSubmit={handleContactFormSubmit}>
					<div className={clsx(s._InputGroup)}>
						<label htmlFor="name">Name*</label>
						<input required type="text" id="name" name="name" placeholder="Enter your name" />
					</div>
					<input type="hidden" name="subject" value="New message from contact form" />
					<div className={clsx(s._InputGroup)}>
						<label htmlFor="email">Email*</label>
						<input
							required
							type="text"
							id="email"
							name="email"
							placeholder="Enter your email address"
						/>
					</div>
					<div className={clsx(s._InputGroup)}>
						<label htmlFor="company">Company*</label>
						<input
							required
							type="text"
							id="company"
							name="company"
							placeholder="What company do you represent?"
						/>
					</div>
					<div className={clsx(s._InputGroup)}>
						<label htmlFor="company">Subject*</label>
						<select name="subject" id="subject">
							<option value="Industry information request">Industry information request</option>
							<option value="Transportation information request">
								Transportation information request
							</option>
							<option value="Town/City information request">Town/City information request</option>
							<option value="Renewable energy provider request">
								Renewable energy provider request
							</option>
							<option value="Press information request">Press information request</option>
							<option value="Speculative job application">Speculative job application</option>
							<option value="Application for an advertised position">
								Application for an advertised position
							</option>
							<option value="Business partnership request">Business partnership request</option>
							<option value="Technical partnership request">Technical partnership request</option>
							<option value="Other">Other</option>
						</select>
					</div>
					<div className={clsx(s._InputGroup)}>
						<label htmlFor="message">Message*</label>
						<textarea
							required
							id="message"
							name="message"
							placeholder="Type your message here..."
							rows={6}
						/>
					</div>
					<div className={clsx(s._Recaptcha)}>
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
							onChange={(value) => setRecaptchaValue(value)}
						/>
					</div>
					<button>
						Submit
						<svg
							width="26"
							height="13"
							viewBox="0 0 26 13"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M18.6849 0L17.4554 1.0925L22.6716 5.72752H0V7.27263H22.6714L17.4554 11.9075L18.6849 13L26 6.5L18.6849 0Z"
								fill="currentColor"
							/>
						</svg>
					</button>
					{showSuccessMessage && (
						<div className={clsx(s._SuccessMessage)}>
							<svg
								width="42"
								height="30"
								viewBox="0 0 42 30"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M40 2L13.875 28L2 16.182"
									stroke="white"
									stroke-width="4"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<span>Your message was sent successfully!</span>
						</div>
					)}

					{showErrorMessage && (
						<div className={clsx(s._ErrorMessage)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="48"
								height="48"
								viewBox="0 0 48 48"
								fill="none"
							>
								<path
									d="M22 30H26V34H22V30ZM22 14H26V26H22V14ZM24 4C12.94 4 4 13 4 24C4 29.3043 6.10714 34.3914 9.85786 38.1421C11.715 39.9993 13.9198 41.4725 16.3463 42.4776C18.7728 43.4827 21.3736 44 24 44C29.3043 44 34.3914 41.8929 38.1421 38.1421C41.8929 34.3914 44 29.3043 44 24C44 21.3736 43.4827 18.7728 42.4776 16.3463C41.4725 13.9198 39.9993 11.715 38.1421 9.85786C36.285 8.00069 34.0802 6.5275 31.6537 5.52241C29.2272 4.51732 26.6264 4 24 4ZM24 40C19.7565 40 15.6869 38.3143 12.6863 35.3137C9.68571 32.3131 8 28.2435 8 24C8 19.7565 9.68571 15.6869 12.6863 12.6863C15.6869 9.68571 19.7565 8 24 8C28.2435 8 32.3131 9.68571 35.3137 12.6863C38.3143 15.6869 40 19.7565 40 24C40 28.2435 38.3143 32.3131 35.3137 35.3137C32.3131 38.3143 28.2435 40 24 40Z"
									fill="white"
								/>
							</svg>
							<span>Oops! there was an error in sending your message. </span>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default ContactForm;
