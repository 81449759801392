import anthonyPic from "@/img/team/Anthony_Mallows1.png";
import christoperPic from "@/img/team/Christopher_1.png";
import PeterPic from "@/img/team/Peter.webp";
import Aos from "aos";
import clsx from "clsx";
import { useEffect } from "react";
import s from "./HomeTeam.module.scss";
import HomeTeamItem from "./HomeTeamItem.component";

const arrTeam = [
	{
		name: "Christopher Adams",
		role: "Co-Founder / CEO",
		description:
			"Christopher is an accomplished German technology innovator who has been honored by Bill Gates for his expertise in microelectronics, micromechanics, AI algorithms, and frequency physics. With 30 years of engineering experience and numerous patents, Christopher has developed a novel method that has achieved major efficiency gains in tests to increase green hydrogen production. This breakthrough technology can enable affordable, renewable hydrogen at scale.",
		linkedinUrl: "https://www.linkedin.com/in/christopher-adams-a6bbb710/",
		// twitterUrl: "https://twitter.com/ChrisGhyser",
		twitterUrl: "",
		ImageData: christoperPic,
	},
	{
		name: "Anthony Mallows",
		role: "Board Member",
		description:
			"Anthony is an architect and urban planner with decades of experience in property development and sustainable planning. He played a pivotal role in MASDAR CITY's development in Abu Dhabi and held leadership positions at Sorouh Real Estate PJSC. With a background in Sasaki Associates and Hart Howerton, he's been instrumental in projects spanning the globe.",
		linkedinUrl: "https://www.linkedin.com/in/anthony-mallows-56904a45/",
		twitterUrl: "",
		ImageData: anthonyPic,
	},
	// {
	// 	name: "Benson van den Heuvel",
	// 	role: "Managing Director / Co-Founder",
	// 	description:
	// 		"Benson’s industry connections drive strategic partnerships, exemplified by the team he has curated. His visionary approach and dynamic leadership are instrumental in propelling Ghyser’s growth in renewable energy, ensuring unparalleled success. Benson’s expertise includes identifying green hydrogen production nations, securing off-take partnerships, and attracting multiple investors. With a history of managing teams exceeding 200 members, he’s adept at scaling businesses. ",
	// 	linkedinUrl: "",
	// 	twitterUrl: "",
	// 	ImageData: BensonPic,
	// },
	{
		name: "Peter Knez",
		role: "Board Member",
		description:
			"Former Chief Investment Officer at BlackRock, following its acquisition of Barclays Global Investors (“BGI”) where he was Global Chief Investment Officer for fixed income. In BGI, Peter grew AUM from $5 billion to $40 billion in the U.S., doubled AUM to $80 billion globally, and achieved 28% revenue growth year over year. Prior to that, Peter held positions with Lincoln Capital Management and Goldman Sachs, where he led quantitative research and fixed income.",
		linkedinUrl: "https://www.linkedin.com/in/pknez/",
		twitterUrl: "",
		ImageData: PeterPic,
	},
];

const HomeTeam = () => {
	useEffect(() => {
		Aos.init({ duration: 500, offset: 50, easing: "ease" });
	}, []);

	return (
		<div className={clsx(s._Wrapper)} id="team">
			<div className={clsx("container")}>
				<div className={clsx(s._Header)}>
					<h2 className={clsx(s._Title)}>
						Visionary Leadership<span>.</span>
					</h2>
					<p className={s._HeaderText}>
						Ghyser is guided by a visionary leadership team with an unwavering commitment to combat
						climate change and revolutionize the energy sector.
					</p>
				</div>
				<div className={clsx(s._TeamItemContainer)}>
					{arrTeam?.map((team, key) => (
						<HomeTeamItem
							key={key}
							name={team.name}
							role={team.role}
							description={team.description}
							linkedinUrl={team.linkedinUrl}
							twitterUrl={team.twitterUrl}
							imageData={team.ImageData}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default HomeTeam;
