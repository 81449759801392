import BadgePill from "@/components/atoms/BadgePill";
import Footer from "@/components/organisms/Footer";
import Header from "@/components/organisms/Header";
import SliderNewsContainer from "@/components/organisms/SliderNewsContainer";
import { arrNewsData } from "@/data/news.data";
import clsx from "clsx";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NewsSingleContentTextSection from "../components/NewsSingleContentTextSection";
import s from "./NewsSingle.module.scss";

const NewsSingle = () => {
	const location = useLocation();
	const currentNewsSlug = location.pathname.split("/").pop();
	const currentNewsData = [...arrNewsData]?.find((item) => item.newsSlug === currentNewsSlug);

	console.log(currentNewsData);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [currentNewsSlug]);

	if (!currentNewsData) return;

	return (
		<>
			<Header isDarkText />
			<div className={clsx(s._Wrapper)}>
				<div className={clsx(s._NewsHead, "container")}>
					<h1 className={clsx(s._Title)}>
						{currentNewsData.newsName}
						<span>.</span>
					</h1>
					<p className={clsx(s._PostDate)}>{currentNewsData?.newsPostDate}</p>
					<div className={clsx(s._CategoryWrapper)}>
						{currentNewsData?.newsCategories?.map((categoryItem, key) => (
							<BadgePill key={key}>{categoryItem}</BadgePill>
						))}
					</div>
				</div>
				{currentNewsData?.newsThumbnail && (
					<div className={clsx(s._ThumbnailWrapper)}>
						<img
							src={currentNewsData?.newsThumbnail}
							alt={currentNewsData?.newsName}
							width={1920}
							height={822}
							loading="eager"
						/>
					</div>
				)}
				<div className={clsx(s._ContentSectionsWrapper, "container")}>
					<p>{currentNewsData.newsDesctiption}</p>
					{currentNewsData?.newsContentSections?.map((sectionItem, key) => (
						<NewsSingleContentTextSection
							title={sectionItem.title}
							description={sectionItem.description}
							key={key}
						/>
					))}
				</div>
				<SliderNewsContainer newsData={arrNewsData} className={clsx(s._Slider)} />
			</div>
			<Footer />
		</>
	);
};

export default NewsSingle;
