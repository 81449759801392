import ArrowButton from "@/components/atoms/ArrowButton";
import EmbedVideo from "@/components/atoms/EmbedVideo";
import bg from "@/features/home/assets/green-hydrogen-bg.webp";
import videoHero from "@/video/green-hydrogen.mp4";
import AOS from "aos";
import clsx from "clsx";
import { useEffect, useState } from "react";
import s from "./HomeGreenHydrogen.module.scss";

const HomeGreenHydrogen = () => {
	const [isVideoReady, serIsVideoReady] = useState(false);

	const handleOnLoadVideo = () => {
		serIsVideoReady(true);
	};

	useEffect(() => {
		AOS.init({
			duration: 1000,
			offset: 50,
			easing: "ease",
		});
	}, []);
	return (
		<div className={s._Wrapper} id="hydrogen">
			<EmbedVideo
				onLoadedDataVideo={handleOnLoadVideo}
				videoData={videoHero}
				className={s._Video}
			/>
			<img
				src={bg}
				width={1920}
				height={579}
				style={{ opacity: isVideoReady ? "0" : "1" }}
				alt="contact-bg"
			/>
			<div className={clsx(s._Container, "container")}>
				<div className={s._Card}>
					<div className={s._Title}>Green Hydrogen</div>
					<div className={s._Text}>
						At Ghyser, we envision a future where renewable energy reigns supreme, and green
						hydrogen becomes the driving force of sustainability.
					</div>
					<ArrowButton text="Switch To Renewable Hydrogen" href="/contact" />
				</div>
			</div>
		</div>
	);
};

export default HomeGreenHydrogen;
