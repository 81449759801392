import { Icon } from "@/components/atoms/Icons";
import clsx from "clsx";
import { Link } from "react-router-dom";
import HeaderLogo from "../HeaderLogo";
import s from "./HeaderMenu.module.scss";

/**
 * @typedef {object} HeaderMenuProps
 * @property {boolean} isActive
 * @property {boolean} [isDarkText]
 * @property {() => void} handleClick
 * @property {string} activeItem
 * @param {HeaderMenuProps} param1
 */
const HeaderMenu = ({ isActive, isDarkText, handleClick, activeItem }) => {
	return (
		<div className={clsx(s._Wrapper, isActive && s._IsActive)}>
			<div className={s._HeaderLogoMobile}>
				<HeaderLogo isActive={false} isDarkText={true} />
			</div>
			<div className={s._Buttons}>
				<Link
					to={"/about-us"}
					onClick={handleClick}
					className={clsx(
						s._Button__Item,
						isDarkText && s._IsDarkText,
						activeItem === "about-us" && s._IsActive,
					)}
				>
					About Us
				</Link>
				<Link
					to={"/our-process"}
					onClick={handleClick}
					className={clsx(
						s._Button__Item,
						isDarkText && s._IsDarkText,
						activeItem === "our-process" && s._IsActive,
					)}
				>
					Our Process
				</Link>
				<Link
					to={"/vision"}
					onClick={handleClick}
					className={clsx(
						s._Button__Item,
						isDarkText && s._IsDarkText,
						activeItem === "vision" && s._IsActive,
					)}
				>
					Vision
				</Link>
				<Link
					to={"/news"}
					onClick={handleClick}
					className={clsx(
						s._Button__Item,
						isDarkText && s._IsDarkText,
						activeItem === "news" && s._IsActive,
					)}
				>
					News
				</Link>
				<Link
					to={"/contact"}
					onClick={handleClick}
					className={clsx(
						s._Button__Item,
						isDarkText && s._IsDarkText,
						activeItem === "contact" && s._IsActive,
					)}
				>
					Contact Us
				</Link>
				<Link
					to={"/investors"}
					onClick={handleClick}
					className={clsx(s._Button__Item, s._Contained, activeItem === "investors" && s._IsActive)}
				>
					For Investors
				</Link>
			</div>
			<div className={s._Foot}>
				<div className={s._GetInTouch}>
					<Link to={"/contact"} className="burger-footer__button burger-footer-bbtn">
						<span className={s._AnimatedDot}></span>
						<span className="burger-footer-bbtn__text">Get in touch</span>
					</Link>
				</div>
				<div className={s._Social}>
					<a
						href="https://www.linkedin.com/company/ghyser/"
						className="burger-footer__link"
						target="blank"
					>
						<Icon.IcLinkedin />
					</a>
					<a href="https://discord.gg/ghyser" className="burger-footer__link" target="blank">
						<Icon.IcDiscord />
					</a>
					<a href="https://twitter.com/GhyserGroup" className="burger-footer__link" target="blank">
						<Icon.IcXFill />
					</a>
				</div>
				<div className={s._CopyRight}>Ghyser ©2023 All Rights Reserved</div>
			</div>
		</div>
	);
};

export default HeaderMenu;
